<template>
  <v-card class="rounded-0 h-full position-relative overflow-hidden">
    <!-- Grid system  -->
    <v-card class="ma-4">
      <v-card-title>Grid system (Auto)</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="grid-container-auto">
          <v-hover
            v-for="item in itemList"
            :key="item.id"
            #default="{ hover: isHovered }"
          >
            <div
              :class="[{'hovered elevation-3': isHovered}, { 'product-demander-favorite': true }]"
              class="grid-box cursor-pointer"
            >
              <div class="d-flex flex-column pa-4">
                <span>{{ item.title }}</span>
                <span>{{ item.description }}</span>
              </div>
            </div>
          </v-hover>
        </div>
      </v-card-text>
    </v-card>
    <!-- // Grid system Auto -->

    <!-- Grid system  -->
    <v-card class="ma-4">
      <v-card-title>Grid system</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="grid-container">
          <div
            v-for="item in itemList"
            :key="item.id"
            class="grid-box cursor-pointer"
          >
            <div class="d-flex flex-column pa-4">
              <span>{{ item.title }}</span>
              <span>{{ item.description }}</span>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <!-- // Grid system -->

    <!-- Table Card -->
    <v-card class="ma-4">
      <v-card-title>Table Card (Used)</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row
          dense
          class="row-container my-4"
        >
          <v-col
            v-for="item in itemList"
            :key="item.id"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
          >
            <v-hover
              #default="{ hover: isHovered }"
            >
              <div
                :class="[{'hovered elevation-3': isHovered}]"
                class="grid-box cursor-pointer"
              >
                <div class="d-flex flex-column pa-4">
                  <span>{{ item.title }}</span>
                  <span>{{ item.description }}</span>
                </div>
              </div>
            </v-hover>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- // Table Card -->
  </v-card>
</template>

<script>
export default {
  setup() {
    const itemList = [
      { id: 1, title: 'title-1', description: 'description-1 short' },
      { id: 2, title: 'title-2', description: 'description-2 super descriptiem very very long' },
      { id: 3, title: 'title-3', description: 'description-3 super short' },
      { id: 4, title: 'title-4', description: 'description-4' },
    ]

    return {
      itemList,
    }
  },
}
</script>
<style lang="scss" scoped>

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .grid-box {
    border-radius: 5.0004px;
    border: 1.0008px solid rgba(94, 86, 105, 0.14);;
    // background: lightgray;
  }

   // GRID (Auto)
  .grid-container-auto {

  // Grid usado en MENU
  // width: calc(100vw - 16.0002px); // Mejora el fliker de recalculo del ancho
    // width: calc(100% - 16.0002px);

    display: grid;
    grid-template-columns: repeat(auto-fill, 300.0006px); //
    grid-auto-rows: 1fr;
    // grid-auto-flow: column;
    // grid-auto-columns: 1fr 1fr;
    gap: 7.9992px;

    width: calc(100% - 16.0002px);

    // Ya se han descontado los mágenes
    margin-left: 7.9992px;
    margin-right: 7.9992px;
  }
  .grid-container-auto > * {
    // min-width: 300.0006px;
    // margin: 0 auto; // Alinear al centro cada GRID-BOX
  }

  // GRID (Manual)
  .grid-container {
    // width: calc(100vw - 16.0002px); // Mejora el fliker de recalculo del ancho
    // width: calc(100% - 16.0002px);
    // margin: 0 auto;

    // Same Width and Height
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;

    // Gaps
    grid-column-gap: 7.9992px;
    grid-row-gap: 7.9992px;

    // Márgenes
    margin-left: 7.9992px;
    margin-right: 7.9992px;
  }
  .grid-container > * {
    width: 300.0006px;
    margin: 0 auto; // Alinear al centro cada GRID-BOX
  }

  @media (min-width: 599.9994px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 959.9994px) {
    .grid-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 1200.0006px) {
    .grid-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 1499.9994px) {
    .grid-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  // ROW/COLS
  .row-container {
    margin-left: 7.9992px;
    margin-right: 7.9992px;
  }
  .row-container > * {
    // max-width: 300.0006px;
  }

</style>
